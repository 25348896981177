<template>
 <div>
  <svg height="183" viewBox="0 0 183 183" width="183" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient" x1="0.724" x2="0.229" y2="1">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <g id="icon_event" transform="translate(-1228 -2560)">
    <g data-name="Group 435" id="Group_435">
     <g id="icon_ticket" transform="translate(197 -4)">
      <rect :fill="background" data-name="Rectangle 193" height="183" id="Rectangle_193" transform="translate(1031 2564)" width="183"/>
     </g>
     <path d="M99.755,42.4V33.422C72.822,24.444,50.378,2,50.378,2S27.933,24.444,1,33.422V42.4A15.7,15.7,0,0,0,11.055,57.034c-.18,11.222-1.436,24.375-5.566,34.744H95.267C91.137,81.408,89.88,68.256,89.7,57.034A15.7,15.7,0,0,0,99.755,42.4ZM50.378,14.165A168.844,168.844,0,0,0,77.8,33.422H22.951A168.844,168.844,0,0,0,50.378,14.165ZM54.867,42.4H68.333a6.733,6.733,0,1,1-13.467,0ZM39.156,49.133A6.724,6.724,0,0,1,32.422,42.4H45.889A6.724,6.724,0,0,1,39.156,49.133ZM23.444,42.4a6.733,6.733,0,1,1-13.467,0ZM17.429,82.8a135.533,135.533,0,0,0,2.559-25.048,15.545,15.545,0,0,0,7.9-4.4A16.648,16.648,0,0,0,31.7,56.136c-.449,8.394-1.167,17.956-2.334,26.619H17.429Zm53.911,0c-1.212-8.708-1.93-18.27-2.334-26.619A15.73,15.73,0,0,0,72.822,53.4a15.878,15.878,0,0,0,7.9,4.4,135.532,135.532,0,0,0,2.559,25.048H71.341Zm12.7-33.667A6.724,6.724,0,0,1,77.311,42.4H90.778A6.724,6.724,0,0,1,84.044,49.133Z" data-name="Path 508" fill="url(#linear-gradient)" id="Path_508" transform="translate(1269.123 2604.611)"/>
    </g>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>